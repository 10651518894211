import { ReactNode, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { Features } from '@lessonPlanner/constants';

import { getGeolocation } from '../api/external/Geolocation';

import AnnouncementBanner from './AnnouncementBanner';
import Loader from './Loader';

interface Props {
  children: ReactNode;
}

function MainLayout(props: Props) {
  const { children } = props;
  const [ip, setIp] = useState<string>();
  const { isLoading, mutateAsync } = getGeolocation(async (res) => {
    Cookies.set('country', res.country);
  });

  useEffect(() => {
    const getUserIp = async () => {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setIp(data.ip);
    };
    getUserIp();
  });

  useEffect(() => {
    console.log('User IP:', ip);
    if (ip) {
      mutateAsync(ip);
      // posthog.identify(ip);
    }
  }, [ip]);

  return (
    <div className="relative">
      <AnnouncementBanner featureFlag={Features.ANNOUNCEMENT_BANNER} />
      {isLoading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center">
          <Loader position="relative" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default MainLayout;
