export const SUMMATIVETEST_PROMPT_NAME = 'summativeTest';
export const SUMMATIVETEST_MATATAG_PROMPT_NAME = 'matatagSummativeTest';
export const SUMMATIVETEST_MAX_TOPICS = 10;
export const SUMMATIVETEST_MAX_SUBTOPICS = 6;
export const SUMMATIVETEST_ENABLED_FORMATS = ['MATATAG', 'STANDARD'];

export const IB_SUMMATIVE_TEST_PROMPT_NAME = 'ibSummativeTest';

export const SUMMATIVETEST_ASSESSMENTTYPE_OPTIONS = [
    'Multiple Choice',
    'True or False',
    'Fill in the blanks',
    'Short Answer'
  ];
  
