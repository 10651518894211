export const DAILY_LESSON_LOG_KINDER_PROMPT_NAME = 'dailyLessonLogKinder';
export const DAILY_LESSON_LOG_G1G12_PROMPT_NAME = 'dailyLessonLogG1G12';
export const DAILY_LESSON_LOG_MATATAG_PROMPT_NAME = 'matatagDLL';
export const dllSections = {
  objectives: {
    title: 'I. OBJECTIVES',
    definition:
      'Objectives must be met over the week and connected to the curriculum standards. To meet the objectives, necessary procedures must be followed and if needed, additional lessons, exercises and remedial activities may be done for developing content knowledge and competencies. These are assessed using Formative Assessment strategies. Valuing objectives support the learning of content and competencies and enable children to find significance and joy in learning the lessons. Weekly objectives shall be derived from the curriculum guides.',
    subContentText: [
      'A. Content Standards',
      'B. Performance Standards',
      'C. Learning Competencies / Objectives Write the LC code for each',
    ],
  },
  content: {
    title: 'II. CONTENT',
    definition:
      'Content is what the lesson is all about. It Pertains to the subject matter that the teacher aims to teach in the CG, the content can be tacked in a week or two.',
  },
  learningResources: {
    title: 'III. LEARNING RESOURCES',
    definition:
      'List the materials to be used in different days. These sources of learning sustain children’s interest in the lesson and in learning. Ensure that there is a mix of concrete and manipulative materials as well as appropriate materials. Helps in learning, enhances concept development.',
    subContentText: [
      'A. References',
      '1. Teacher’s Guide pages',
      '2. Learner’s Materials pages',
      '3. Textbook pages',
      '4. Additional Materials from Learning Resource (LR) portal',
      'B. Other Learning Resources',
    ],
  },
  procedures: {
    title: 'IV. PROCEDURES',
    definition:
      'These steps should be done across the week. Spread out the activities appropriately for total student well-being. Always be guided by characteristics of learning for the students which you can infer from formative assessment activities. Balance the learning experiences by providing students with multiple opportunities to learn new things, practice and reviewing, confirm their learning experiences, and their opportunities to show what they learned through various means and express themselves. Include time intervals for each step.',
    subContentText: [
      'A. Reviewing previous lesson or presenting the new lesson',
      'B. Establishing a purpose for the lesson',
      'C. Presenting examples/instances of the new lesson',
      'D. Discussing new concepts and practicing new skills #1',
      'E. Discussing new concepts and practicing new skills #2',
      'F. Developing mastery (Leads to Formative Assessment 3)',
      'G. Finding practical applications of concepts and skills in daily living',
      'H. Making generalizations and abstractions about the lesson',
      'I. Evaluating learning',
      'J. Additional activities for application or remediation',
    ],
  },
  remarks: {
    title: 'V. REMARKS',
  },
  reflection: {
    title: 'VI. REFLECTION',
    definition:
      "Reflect on your teaching and assess yourself as a teacher. Think about your students' progress this week. What works? What else needs to be done to help the students learn? Identify what help your instructional supervisors can provide for you so that you meet them, you can use the following questions:",
    subContentText: [
      'A. No. of learners who earned 80% in the evaluation.',
      'B. No. of learners who require additional activities for remediation who scored below 80%.',
      'C. Did the remedial lessons work? No. of learners who have caught up with the lesson.',
      'D. No. of learners who continue to require remediation.',
      'E. Which of my teaching strategies worked well? Why did these work?',
      'F. What difficulties did I encounter which my principal or supervisor can help me solve?',
      'G. What innovation or localized materials did I use/discover which I wish to share with other teachers?',
    ],
  },
};
export const dllSectionsFilipino = {
  objectives: {
    title: 'I. LAYUNIN',
    definition:
      'Objectives must be met over the week and connected to the curriculum standards. To meet the objectives, necessary procedures must be followed and if needed, additional lessons, exercises and remedial activities may be done for developing content knowledge and competencies. These are assessed using Formative Assessment strategies. Valuing objectives support the learning of content and competencies and enable children to find significance and joy in learning the lessons. Weekly objectives shall be derived from the curriculum guides.',
    subContentText: [
      'A. Pamantayang Pangnilalaman',
      'B. Pamantayan sa Pagganap',
      'C. Mga Kasanayan sa Pagkatuto',
    ],
  },
  content: {
    title: 'II. NILALAMAN',
    definition:
      'Content is what the lesson is all about. It Pertains to the subject matter that the teacher aims to teach in the CG, the content can be tacked in a week or two.',
  },
  learningResources: {
    title: 'III. KAGAMITANG PANTURO',
    definition:
      'List the materials to be used in different days. These sources of learning sustain children’s interest in the lesson and in learning. Ensure that there is a mix of concrete and manipulative materials as well as appropriate materials. Helps in learning, enhances concept development.',
    subContentText: [
      'A. Sanggunian',
      '1. Mga Pahina sa Gabay ng Guro',
      '2. Mga Pahina sa Kagamitang Pang-Mag-aaral',
      '3. Mga Pahina sa Teksbuk',
      '4. Karagdagang Kagamitan mula sa portal ng Learning Resource',
      'B. Iba pang Kagamitang Panturo',
    ],
  },
  procedures: {
    title: 'IV. PAMAMARAAN',
    definition:
      'These steps should be done across the week. Spread out the activities appropriately for total student well-being. Always be guided by characteristics of learning for the students which you can infer from formative assessment activities. Balance the learning experiences by providing students with multiple opportunities to learn new things, practice and reviewing, confirm their learning experiences, and their opportunities to show what they learned through various means and express themselves. Include time intervals for each step.',
    subContentText: [
      'A. Balik-aral sa nakaraang aralin at/o pagsisimula ng bagong aralin',
      'B. Paghahabi sa layunin ng aralin',
      'C. Pag-uugnay ng mga halimbawa sa bagong aralin',
      'D. Pagtalakay ng bagong konsepto at pagsasanay ng bagong kasanayan #1:',
      'E. Pagtalakay ng bagong konsepto at pagsasanay ng bagong kasanayan #2:',
      'F. Paglinang sa kabihasaan',
      'G. Paglalapat ng aralin sa pang-araw-araw na buhay',
      'H. Paglalahat ng aralin',
      'I. Pagtataya ng aralin',
      'J. Karagdagang gawain para sa takdang-aralin at remediation',
    ],
  },
  remarks: {
    title: 'V. MGA TALA',
  },
  reflection: {
    title: 'VI. PAGNINILAY',
    definition: '',
    subContentText: [
      'Bilang ng mag-aaral na nakakuha ng 80% sa pagtataya',
      'Bilang ng mag-aaral na nangangailangan ng iba pang gawain para sa remediation',
      'Nakatulong ba angremedial?',
      'Bilang ng mag-aaral na nakaunawa sa aralin.',
      'Bilang ng mag-aaral na magpapatuloy saremediation',
      'Alin sa mga istratehiyang pagtuturo ang nakatulong ng lubos?',
      'Paano ito nakatulong?',
    ],
  },
};

export const dllSectionsMatatag = {
  objectives: {
    title: 'I. CURRICULUM CONTENT, STANDARDS, AND LESSON COMPETENCIES',
    definition: '',
    subContentText: [
      'A. Content Standards',
      'B. Performance Standards',
      'C. Learning Competencies',
      'D. Learning Objectives',
    ],
  },
  content: {
    title: 'II. CONTENT',
    definition: '',
  },
  learningResources: {
    title: 'III. LEARNING RESOURCES',
    definition: '',
    subContentText: [
      'A. References',
      'B. Other Learning Resources',
      'C. Anchorage',
    ],
  },
  procedures: {
    title: 'IV. TEACHING AND LEARNING PROCEDURES',
    definition: '',
    subContentText: [
      'A. Activating Prior Knowledge',
      'B. Lesson Purpose / Intention',
      'C. Lesson Language Practice',
      'D. Reading the Key Idea/Stem',
      'E. Developing Understanding of the Key Idea/Stem',
      'F. Deepening Understanding of the Key Idea/Stem',
      'G. Making generalizations and abstractions about the lesson',
      'H. Evaluating learning',
      'I. Additional activities for application or remediation',
    ],
  },
  remarks: {
    title: 'V. REMARKS',
  },
  reflection: {
    title: 'VI. REFLECTION',
    definition: '',
    subContentText: []
  },
};

export const dllSectionsMatatagFilipino = {
  objectives: {
    title: 'I. NILALAMAN, MGA PAMANTAYAN, AT MGA KASANAYANG PAMPAGKATUTO NG KURIKULUM',
    definition: '',
    subContentText: [
      'A. Pamantayang Pangnilalaman',
      'B. Pamantayang Pagganap',
      'C. Mga Kasanayanng Pampagkatuto',
      'D. Mga Layunin',
      'E. Integrasyon',
    ],
  },
  content: {
    title: 'II. NILALAMAN / PAKSA',
    definition: '',
  },
  learningResources: {
    title: 'III. MGA KAGAMITANG PANTURO AT PAMPAGKATUTO',
    definition: '',
    subContentText: [
      'A. Mga Sanggunian',
      'B. Iba pang Kagamitan ng Pagkatuto',
    ],
  },
  procedures: {
    title: 'IV. MGA PAMARAANG PANTURO AT PAMPAGKATUTO',
    definition: '',
    subContentText: [
      'A. Panimulang Gawain',
      'B. Gawaing Paglalahad ng Layunin ng Aralin',
      'C. Gawaing Pag-unawa sa mga Susing Salita/ Parirala o Mahahalagang Konsepto sa Aralin',
      'D. Pagbasa sa Mahahalagang Pag-unawa/ Susing Ideya',
      'E. Pagpapaunlad ng Kaalaman at Kasanayan sa Mahahalagang Pag-unawa/ Susing Ideya',
      'F. Pagpapalalim ng Kaalaman at Kasanayan sa Mahahalagang Pag-unawa/ Susing Ideya',
      'G. Paglalapat at Paglalahat',
      'H. Pagtataya ng Natutuhan',
      'I. Mga Dagdag na Gawain para sa Paglalapat o para sa Remediation (kung nararapat)',
    ],
  },
  remarks: {
    title: 'V. MGA TALA',
  },
  reflection: {
    title: 'VI. REFLECTION',
    definition: '',
    subContentText: []
  },
};

export const procedures = [
  'Reviewing previous lesson or presenting the new lesson',
  'Establishing purpose for the lesson',
  'Presenting examples / instances of the new lesson',
  'Discussing new concepts and practicing new skills #1',
  'Discussing new concepts and practicing new skills #2',
  'Developing mastery',
  'Finding practical applications of concepts and skills in daily living',
  'Making generalizations and abstractions about the lesson',
  'Evaluating learning',
  'Additional activities for application or remediation',
];

export const proceduresFilipino = [
  'Balik-aral sa nakaraang aralin at/o pagsisimula ng bagong aralin',
  'Paghahabi sa layunin ng aralin',
  'Pag-uugnay ng mga halimbawa sa bagong aralin',
  'Pagtalakay ng bagong konsepto at pagsasanay ng bagong kasanayan #1:',
  'Pagtalakay ng bagong konsepto at pagsasanay ng bagong kasanayan #2:',
  'Paglinang sa kabihasaan',
  'Paglalapat ng aralin sa pang-araw-araw na buhay',
  'Paglalahat ng aralin',
  'Pagtataya ng aralin',
  'Karagdagang gawain para sa takdang-aralin at remediation',
];

export const proceduresMatatag = [
  'Activating Prior Knowledge',
  'Lesson Purpose / Intention',
  'Lesson Language Practice',
  'Reading the Key Idea',
  'Developing Understanding of the Key Idea',
  'Deepening Understanding of the Key Idea',
  'Making Generalizations and Abstractions',
  'Evaluating Learning',
  'Additional Activities for Application or Remediation',
];

export const proceduresMatatagFilipino = [
  'Pag-activate ng Dating Kaalaman',
  'Layunin ng Aralin',
  'Pagsasanay sa Wika ng Aralin',
  'Pagbasa ng Pangunahing Ideya',
  'Pag-unawa sa Pangunahing Ideya',
  'Pagpapalalim ng Pag-unawa sa Pangunahing Ideya',
  'Paglalahat ng aralin',
  'Pagtataya ng aralin',
  'Karagdagang gawain para sa aplikasyon o remediation',
];