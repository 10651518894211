export enum UnitPlanPromptNames {
  PYP = 'PYP',
  MYP = 'MYP',
  DP = 'DP',
}

export const UP_PROMPT_NAME_MAP = {
  [UnitPlanPromptNames.PYP]: 'Primary Years Programme',
  [UnitPlanPromptNames.MYP]: 'Middle Years Programme',
  [UnitPlanPromptNames.DP]: 'Diploma Programme',
} as const;

export const UP_TRANSDISCIPLINARY_THEMES = [
  'Who we are',
  'Where we are in place and time',
  'How we express ourselves',
  'How the world works',
  'How we organize ourselves',
  'Sharing the planet',
];
export const UP_GLOBAL_CONTEXTS = [
  'Identities and relationships',
  'Orientation in space and time',
  'Personal and cultural expression',
  'Scientific and technical innovation',
  'Globalization and sustainability',
  'Fairness and development',
];

export const UP_LEARNER_PROFILE_ATTRIBUTES = [
  'Inquirers',
  'Knowledgeable',
  'Thinkers',
  'Communicators',
  'Principled',
  'Open-minded',
  'Caring',
  'Risk-takers',
  'Balanced',
  'Reflective',
];
