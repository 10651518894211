export const PPT_MAKER_PROMPT_NAME = 'pptMaker';
export const PPT_MAKER_DOC_CHAR_LIMIT = 20000;

export interface ThemeColors {
  background: string;
  text: string;
}

export enum ThemeType {
  WHITE = 'WHITE',
  PINK = 'PINK',
  GRAY = 'GRAY',
  SAGE = 'SAGE',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
}

export enum FontOption {
  ARIAL = 'Arial',
  TIMES_NEW_ROMAN = 'Times New Roman',
  MONTSERRAT = 'Montserrat',
}

export const PPT_MAKER_FONT_OPTIONS = {
  [FontOption.ARIAL]: 'Arial',
  [FontOption.TIMES_NEW_ROMAN]: 'Times',
  [FontOption.MONTSERRAT]: 'Montserrat',
};

export const PPT_MAKER_THEMES: Record<ThemeType, ThemeColors> = {
  [ThemeType.WHITE]: {
    background: '#FFFFFF',
    text: '#333333',
  },
  [ThemeType.PINK]: {
    background: '#FFEFF9',
    text: '#333333',
  },
  [ThemeType.GRAY]: {
    background: '#E8E7E4',
    text: '#333333',
  },
  [ThemeType.SAGE]: {
    background: '#CCDBC6',
    text: '#333333',
  },
  [ThemeType.BLUE]: {
    background: '#38B6FF',
    text: '#FFFFFF',
  },
  [ThemeType.ORANGE]: {
    background: '#FAA94A',
    text: '#FFFFFF',
  },
};

export const THEME_CIRCLE_STYLES = {
  WHITE: 'border border-gray-200 bg-white text-gray-900',
  PINK: 'bg-pink-100 text-gray-900',
  GRAY: 'bg-gray-200 text-gray-900',
  SAGE: 'bg-green-100 text-gray-900',
  BLUE: 'bg-blue-400 text-white',
  ORANGE: 'bg-orange-400 text-white',
} as const;

export enum TextAlignment {
  LEFT = 'left',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export interface TextStyle {
  fontSize: number;
  fontWeight: FontWeight;
  alignment: TextAlignment;
}

export const TITLE_STYLE: TextStyle = {
  fontSize: 100,
  fontWeight: FontWeight.BOLD,
  alignment: TextAlignment.CENTER,
};

export const HEADER_STYLE: TextStyle = {
  fontSize: 46,
  fontWeight: FontWeight.BOLD,
  alignment: TextAlignment.LEFT,
};

export const BODY_STYLE: TextStyle = {
  fontSize: 36,
  fontWeight: FontWeight.NORMAL,
  alignment: TextAlignment.JUSTIFY,
};

export const DEFAULT_TYPOGRAPHY = {
  titleStyle: TITLE_STYLE,
  headerStyle: HEADER_STYLE,
  bodyStyle: BODY_STYLE,
  lineSpacing: 1.5,
};

export const FONTS = [
  { id: FontOption.ARIAL, name: FontOption.ARIAL },
  { id: FontOption.TIMES_NEW_ROMAN, name: FontOption.TIMES_NEW_ROMAN },
  { id: FontOption.MONTSERRAT, name: FontOption.MONTSERRAT },
];

export const THEME_OPTIONS = Object.entries(PPT_MAKER_THEMES).map(
  ([id, colors]) => ({
    id: id as ThemeType,
    name:
      id === ThemeType.WHITE
        ? 'Basic'
        : id.charAt(0) + id.slice(1).toLowerCase(),
    background: colors.background,
    text: colors.text,
  }),
);
